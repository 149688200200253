import styles from './styles.module.css';

type Props = {
  direction: 'up' | 'down';
};

export const TriangleIcon = ({ direction = 'up' }: Props) => {
  const rotationClass = direction === 'down' ? styles.down : styles.up;
  return (
    <svg
      className={rotationClass}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1472_24085)">
        <path d="M3 6L8 11L13 6H3Z" fill="#7C8291" />
      </g>
      <defs>
        <clipPath id="clip0_1472_24085">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(-4 -4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
