export const DateTimeFormat = {
  jp: new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }),
  jpYearMonthDayJst: new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Asia/Tokyo',
  }),
  jpYearMonthJst: new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: 'long',
    timeZone: 'Asia/Tokyo',
  }),
};

export const CurrencyFormat = {
  jp: new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
    currencyDisplay: 'name',
  }),
};

export const PointFormat = {
  // @ts-ignore: correct documentation but incorret type
  jp: new Intl.NumberFormat('ja-JP', {
    signDisplay: 'auto',
  }),
};
