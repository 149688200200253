import { TriangleIcon } from '../TriangleIcon';
import styles from './styles.module.css';

export type SearchPanelToggleProps = {
  children: React.ReactNode;
  ariaExpanded: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const SearchPanelToggle = ({
  children,
  ariaExpanded,
  onClick,
}: SearchPanelToggleProps) => (
  <button
    className={styles.searchPanelToggle}
    onClick={onClick}
    aria-expanded={ariaExpanded}
    aria-haspopup="true"
  >
    {children}
    <TriangleIcon direction={ariaExpanded ? 'down' : 'up'} />
  </button>
);
