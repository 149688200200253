import { useGlobalMessage } from './useGlobalMessage';
import { useGlobalNotification } from './useGlobalNotification';

export const useResetMessageAndNotification = (): VoidFunction => {
  const { resetGlobalNotification } = useGlobalNotification();
  const { resetGlobalMessage } = useGlobalMessage();

  return () => {
    resetGlobalMessage();
    resetGlobalNotification();
  };
};
