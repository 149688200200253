import { useState } from 'react';
import { SearchPanelActions } from './components/SearchPanelActions';
import { SearchPanelBody } from './components/SearchPanelBody';
import { SearchPanelButtonGroup } from './components/SearchPanelButtonGroup';
import { SearchPanelFilters } from './components/SearchPanelFilters';
import { SearchPanelRow } from './components/SearchPanelRow';
import { SearchPanelToggle } from './components/SearchPanelToggle';
import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
  defaultOpen?: boolean;
};

const SearchPanelBase = ({ children, defaultOpen = false }: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <div className={styles.searchPanel}>
      <SearchPanelBody ariaHidden={!isOpen}>{children}</SearchPanelBody>
      <SearchPanelToggle onClick={handleClick} ariaExpanded={isOpen}>
        絞り込み条件
      </SearchPanelToggle>
    </div>
  );
};

export const SearchPanel = Object.assign(
  {},
  {
    Base: SearchPanelBase,
    Filters: SearchPanelFilters,
    Row: SearchPanelRow,
    Actions: SearchPanelActions,
    ButtonGroup: SearchPanelButtonGroup,
  }
);
