import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Dialog } from '@/components/new/Dialog';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.h2`
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
`;

const NoteWrapper = styled.div``;

const Note = styled.p`
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 16px;
`;

type Props = {
  onClose: () => void;
};

export const CSVExportDialog = ({ onClose }: Props): JSX.Element => {
  return (
    <Dialog.Window
      size="medium"
      onInteractOutside={(e) => e.preventDefault()}
      onEscapeKeyDown={(e) => e.preventDefault()}
      onPointerDownOutside={(e) => e.preventDefault()}
    >
      <Dialog.Contents>
        <ContentWrapper>
          <Heading>CSVエクスポートを開始しました</Heading>
          <NoteWrapper>
            <Note>
              ファイルのサイズが大きすぎるため、エクスポートが完了次第ダウンロードURLをメールで送付します。
              <br />
              この処理には数分かかる場合があります。
            </Note>
          </NoteWrapper>
        </ContentWrapper>
      </Dialog.Contents>
      <Dialog.Footer>
        <Footer>
          <Dialog.Close asChild>
            <Button type="button" size="large" onClick={onClose}>
              OK
            </Button>
          </Dialog.Close>
        </Footer>
      </Dialog.Footer>
    </Dialog.Window>
  );
};
