import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import { PageInfoDetailsFragment } from '@/graphql';
import { LeftArrowIcon } from './LeftArrowIcon';
import { RightArrowIcon } from './RightArrowIcon';
import styles from './styles.module.css';

export type PaginationLinkProps = {
  href?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const Prev = forwardRef<HTMLAnchorElement, PaginationLinkProps>(
  ({ href, disabled, ...rest }, ref) => {
    return (
      <a
        className={styles.a}
        aria-label="前のページ"
        href={disabled ? undefined : href}
        ref={ref}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        {...rest}
      >
        <LeftArrowIcon />
      </a>
    );
  }
);
Prev.displayName = 'Prev';

const Next = forwardRef<HTMLAnchorElement, PaginationLinkProps>(
  ({ href, disabled, ...rest }, ref) => {
    return (
      <a
        className={styles.a}
        aria-label="次のページ"
        href={disabled ? undefined : href}
        ref={ref}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        {...rest}
      >
        <RightArrowIcon />
      </a>
    );
  }
);
Next.displayName = 'Next';

const Item = (props: { children: React.ReactNode }) => (
  <li className={styles.item} {...props} />
);

const BasePagination = ({
  ariaLabel = 'pagination',
  children,
}: {
  children?: React.ReactNode;
  ariaLabel?: string;
}) => (
  <nav aria-label={ariaLabel}>
    <ul className={styles.ul}>{children}</ul>
  </nav>
);

export type PaginationProps = PageInfoDetailsFragment;
export const Pagination = ({
  startCursor,
  endCursor,
  hasPreviousPage,
  hasNextPage,
}: PaginationProps): JSX.Element => {
  const router = useRouter();
  const pathname = router.pathname;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { before, after, cardTransactionId, ...searchQueries } = router.query;

  return (
    <BasePagination>
      <Item>
        <Link
          href={{
            pathname,
            query: {
              before: startCursor,
              ...searchQueries,
            },
          }}
          passHref
          shallow
        >
          <Prev disabled={!hasPreviousPage} />
        </Link>
      </Item>
      <Item>
        <Link
          href={{
            pathname,
            query: {
              after: endCursor,
              ...searchQueries,
            },
          }}
          passHref
          shallow
        >
          <Next disabled={!hasNextPage} />
        </Link>
      </Item>
    </BasePagination>
  );
};
