import styles from './styles.module.css';

type SearchPanelBodyProps = {
  children?: React.ReactNode;
  ariaHidden: boolean;
};

export const SearchPanelBody = ({
  children,
  ariaHidden,
}: SearchPanelBodyProps) => {
  const bodyClass = ariaHidden
    ? `${styles.searchPanelBody} ${styles.searchPanelBodyHidden}`
    : styles.searchPanelBody;

  return (
    <div className={bodyClass} aria-hidden={ariaHidden}>
      {children}
    </div>
  );
};
