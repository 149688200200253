import { FC } from 'react';
import styled from 'styled-components';

// TODO: update name since bulk update button was added to layout
export const PaginationAndExportLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const OnlyPaginationLayout = styled.div`
  margin-bottom: 8px;
`;

const OnlyExportLayout = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 8px;
`;

const NothingLayout = styled.div`
  margin-bottom: 8px;
`;

export type PaginationLayoutType =
  | 'both'
  | 'onlyPage'
  | 'onlyExport'
  | 'nothing';
export const paginationLayouts: Record<PaginationLayoutType, FC> = {
  both: PaginationAndExportLayout,
  onlyPage: OnlyPaginationLayout,
  onlyExport: OnlyExportLayout,
  nothing: NothingLayout,
};
