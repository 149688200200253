import styled from 'styled-components';
import { NavigationBar } from '@/components/ui/NavigationBar';

export type PageLayoutProps = {
  title?: React.ReactNode;
  children?: React.ReactNode;
  backHref?: string;
  backLinkText?: string;
  buttons?: React.ReactNode;
};

const Content = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Box = styled.div`
  margin-bottom: 80px;
`;

export const PageLayout = ({
  children,
  title,
  backHref,
  backLinkText = '戻る',
  buttons = null,
}: PageLayoutProps): JSX.Element => (
  <Box>
    {title && (
      <NavigationBar>
        {backHref && (
          <NavigationBar.Backlink href={backHref}>
            {backLinkText}
          </NavigationBar.Backlink>
        )}
        <NavigationBar.Title>{title}</NavigationBar.Title>
        {buttons && (
          <NavigationBar.Item>
            <Wrapper>{buttons}</Wrapper>
          </NavigationBar.Item>
        )}
      </NavigationBar>
    )}

    <Content>{children}</Content>
  </Box>
);
